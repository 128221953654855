// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as React from "react";
import * as Button from "../../components/Button/Button.res.js";
import * as Popover from "../../components/Popover/Popover.res.js";
import * as IconSelect from "../../icons/IconSelect.res.js";
import * as DropdownScss from "./Dropdown.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = DropdownScss;

function Dropdown(props) {
  return JsxRuntime.jsx(Popover.make, {
              className: props.className,
              onShow: props.onShow,
              onHide: props.onHide,
              children: props.children
            });
}

function Dropdown$Trigger(props) {
  var __isBodyExists = props.isBodyExists;
  var __iconColor = props.iconColor;
  var __iconSize = props.iconSize;
  var __className = props.className;
  var className = __className !== undefined ? __className : "";
  var iconSize = __iconSize !== undefined ? __iconSize : "MD";
  var iconColor = __iconColor !== undefined ? __iconColor : "LighterGray";
  var isBodyExists = __isBodyExists !== undefined ? __isBodyExists : true;
  var ctx = React.useContext(Popover.Context.ctx);
  return JsxRuntime.jsxs(Popover.Trigger.make, {
              className: Cx.cx([
                    css.trigger,
                    className
                  ]),
              children: [
                props.children,
                isBodyExists ? JsxRuntime.jsx(IconSelect.make, {
                        size: iconSize,
                        color: iconColor,
                        className: Cx.cx([
                              css.icon,
                              ctx.shown ? css.flip : ""
                            ])
                      }) : null
              ]
            });
}

var Trigger = {
  make: Dropdown$Trigger
};

function Dropdown$Body(props) {
  var __buttonText = props.buttonText;
  var __withButton = props.withButton;
  var __className = props.className;
  var className = __className !== undefined ? __className : "";
  var withButton = __withButton !== undefined ? __withButton : false;
  var buttonText = __buttonText !== undefined ? __buttonText : "Close";
  var ctx = React.useContext(Popover.Context.ctx);
  return JsxRuntime.jsxs(Popover.Body.make, {
              position: props.position,
              className: Cx.cx([
                    css.body,
                    className
                  ]),
              children: [
                props.children,
                withButton ? JsxRuntime.jsx(Button.make, {
                        size: "SM",
                        color: "Teal",
                        onClick: (function (param) {
                            ctx.toggle();
                          }),
                        children: buttonText
                      }) : null
              ]
            });
}

var Body = {
  make: Dropdown$Body
};

var make = Dropdown;

var Context;

export {
  css ,
  make ,
  Context ,
  Trigger ,
  Body ,
}
/* css Not a pure module */
